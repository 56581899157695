.linkBlock {
	display: block;
	text-decoration: none;

	&:hover {
		.linkBlockTextLink {
		   @apply tw-text-gray-100;
	   }
   }

	&:focus {
		[class~=ButtonAnchor]  {
			@apply tw-text-gray-100;
		}
	}

	&:hover, &:focus {
		.linkWithUnderlineOnHover {
			@apply tw-underline #{'!important'};
		}
	}
}
