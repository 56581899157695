@import 'css/lib/tailwind-custom/triangle';

.favorites-menu {
	position: relative;

	svg {
		.hover-fill {
			fill: theme('colors.white.DEFAULT');
		}
	}

	.subMenu {
		top: 34px;
		text-align: left;
		z-index: 157;

		&.show {
			@apply tw-visible;
		}

		ul {
			list-style-type: none;
			margin: 0;

			li {
				margin-top: 10px;

				a,
				button {
					@apply tw-text-gray tw-block tw-text-sm tw-no-underline;
					&:hover,
					&:focus {
						@apply tw-text-blue tw-underline;
					}
				}
			}
		}
	}

	&.has-submenu {
		&:hover,
		&.has-focus {
			.hover-fill {
				fill: theme('colors.gray.DEFAULT');
			}
			.subMenu {
				visibility: visible;
			}
			.favorites-button::before {
				opacity: 1;
			}
			.favorites-button::after {
				width: 100%;
			}
		}
	}

	.favorites-menu-list {
		left: -90px;
		width: 200px;
		padding: 0 10px 10px 10px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	}
}
