.site-marketing-messaging-background {
	@apply tw-absolute tw-h-full tw-left-0 tw-top-0 tw-transition-colors tw-w-full;
}

.site-marketing-messaging-background-BANNER_BLACK {
	@apply tw-bg-gray;
}

.site-marketing-messaging-background-BANNER_RED {
	@apply tw-bg-red;
}
