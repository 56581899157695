@media screen {
	.mobile-menu-close-container {
		@apply tw-h-0 tw-relative tw-top-[-40px] tw-w-[45px] tw-z-[110];
	}
	.mobile-menu-close {
		@apply tw-relative tw-top-[1px] tw-z-[2005];
	}
	.menu {
		@apply tw-fixed tw-overflow-auto tw-p-0;
		width: 83vw;
		z-index: 2000;
		height: calc(100% - 45px);

		.inside {
			@apply tw-box-border;
			height: max-content;
		}

		.submenu {
			// min-height: 100vh;
			position: relative;
			top: 0;
			transform: translateX(-100%);
			width: 100%;

			.promoSlider {
				@apply tw-border-t-gray tw-items-center;
				.promoSliderHeader {
					@apply tw-flex tw-items-center;
					padding: 16px 10px;

					.promoSliderTitle {
						@apply tw-inline-block tw-text-gray tw-flex-grow;
					}
					.promoSliderShopLink {
						@apply tw-text-gray tw-no-underline tw-flex tw-items-center;

						:nth-child(2) {
							@apply tw-self-start;
							padding-left: 6px;
							line-height: 1.125rem;
						}
					}
				}
				.promoSliderHolder {
					@apply tw-pt-4 tw-bg-gray-500;
					padding-left: 10px;

					:global .ScrollingContainer {
						@apply tw-mb-0 tw-mr-0;
						ul > li > a > span {
							font-size: 17px;
							line-height: 1.3125rem;
						}
					}
				}
				+ ul {
					@apply tw-border-0;
				}
			}
		}
		:global .clearance {
			a:link,
			a:visited {
				@apply tw-text-red tw-font-semibold tw-underline #{!important};
			}
		}
	}

	.menu-has-site-marketing-messages {
		height: calc(100% - 85px);
	}

	// Animations
	@-webkit-keyframes menu-slide-in {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(0);
		}
	}
	@-webkit-keyframes menu-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100%);
		}
	}
	@-moz-keyframes menu-slide-in {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(0);
		}
	}
	@-moz-keyframes menu-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100%);
		}
	}
	.menu-slide-in {
		animation: menu-slide-in 200ms ease-out;
		transform: translateX(0);
	}
	.menu-slide-out {
		animation: menu-slide-out 200ms ease-out;
		transform: translateX(-100%);
	}

	@-webkit-keyframes menu-list-slide-in {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(0);
		}
	}
	@-webkit-keyframes menu-list-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100%);
		}
	}
	@-moz-keyframes menu-list-slide-in {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(0);
		}
	}
	@-moz-keyframes menu-list-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-100%);
		}
	}
	.menu-list-slide-in {
		animation: menu-list-slide-in 200ms ease-out;
		transform: translateX(0);
	}
	.menu-list-slide-out {
		animation: menu-list-slide-out 200ms ease-out;
		transform: translateX(-100%);
		display: none;
	}

	@-webkit-keyframes submenu-slide-in {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(0);
		}
	}
	@-webkit-keyframes submenu-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(100%);
		}
	}
	@-moz-keyframes submenu-slide-in {
		from {
			transform: translateX(100%);
			display: block;
		}
		to {
			transform: translateX(0);
		}
	}
	@-moz-keyframes submenu-slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(100%);
			display: none;
		}
	}
	.menu .submenu-slide-in {
		animation: submenu-slide-in 200ms ease-out;
		transform: translateX(0);
		display: block;
	}
	.menu .submenu-slide-out {
		animation: submenu-slide-out 200ms ease-out;
		transform: translateX(100%);
		display: none;
	}

	.menu-overlay {
		@apply tw-absolute tw-bg-gray tw-left-0 tw-opacity-0 tw-top-0 tw-transition-opacity tw-duration-200 tw-ease-in-out tw-w-full;
		height: calc(100vh);
		z-index: 1000;
	}

	.menu-overlay-active {
		opacity: 0.5;
	}
}
