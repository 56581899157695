.CallanChairIntro-text {
    bottom: 10px;
	width: 375px;
	right: unset;
	top: unset;
}

.CallanChair5Star {
	@apply tw-bg-gray-500 tw-text-center tw-p-5 tw-flex tw-flex-col tw-items-center tw-justify-center;
	max-width: 280px;
	height: 281px;
	p {
		@apply tw-mb-4 #{!important};
	}
}

@screen md {
	.CallanChairIntro-text {
		@apply tw-text-center;
		width: 400px;
		top: 16%;
		right: 16%;
	}
}

@screen lg {
	.CallanChairIntro-text {
		top: 14%;
		right: 8%;

	}
}

@screen xl {
	.CallanChairIntro-text {
		right: 18%;
	}
}