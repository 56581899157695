.subMenu {
    @apply tw-flex tw-flex-wrap tw-justify-start tw-invisible tw-absolute;
    @apply tw-bg-gray-600;

    transition: visibility 0s linear 0.4s;
    @supports (display: grid) {
        display: grid;
    }
    top: 32px;
    left: -1em;
    padding: 20px 15px 5px;
    z-index: 150;
    border-top: 3px solid theme('colors.gray.400');

    &.coll_1 {
        width: 200px;

        .coll-bottomMenu .menu-node > ul > li {
            flex-basis: calc(100% - 10px);
        }
    }

    &.coll_2 {
        width: 360px;

        .coll-bottomMenu .menu-node > ul > li {
            flex-basis: calc(50% - 10px);
        }
    }

    &.coll_3 {
        width: 540px;

        @screen smBsOnly {
            left: -150px !important;
            width: 500px;
        }

        .coll-bottomMenu .menu-node > ul > li {
            flex-basis: calc(33% - 10px);
        }
    }

    &.coll_4 {
        width: 720px;

        @screen smBsOnly {
            left: -175px !important;
            width: 600px;
        }

        .coll-bottomMenu .menu-node > ul > li {
            flex-basis: calc(25% - 10px);
        }
    }

    .menu-node {
        margin: 0 0 15px;

        h2 {
            @apply tw-font-bold tw-text-gray tw-text-base;
            letter-spacing: 1px;
            line-height: 18px;
            margin: 0 0 10px;
            width: 90%;
        }

        ul li {
            margin: 0 0 10px;

            a {
                @apply tw-font-normal tw-text-gray tw-text-sm tw-no-underline;
                line-height: 18px;

                &:hover,
                &:focus {
                    @apply tw-underline tw-text-orange;
                }
            }
        }
    }

    .menu-node-heading {
        @apply tw-font-bold tw-leading-tight tw-text-base;
    }

    .menuColl {
        flex: 1 1 165px;
        margin: 0 5px;

        :global .clearance {
            h2, ul > li > a {
                @apply tw-text-red;
            }
        }

        &.coll-bottomMenu {
            flex-basis: 100%;
            padding: 10px 0 0;

            .menu-node > ul {
                @apply tw-flex tw-flex-wrap tw-justify-start tw-relative;
                left: -5px;
                width: calc(100% + 10px);

                > li {
                    margin: 0 5px 10px;

                    &:last-child {
                        flex-basis: 100%;
                        margin: 10px 0 0;
                    }
                }
            }
        }
    }
}

.subMenu-centered > .subMenu.coll_3 {
    left: -200px;
}

@supports (display: grid) {
    .subMenu {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        padding: 20px;

        &.coll_1 {
            grid-template-columns: 100%;

            .coll-bottomMenu {
                grid-column-end: span 1;

                .menu-node > ul {
                    grid-template-columns: 100%;

                    > li:last-child {
                        grid-column-end: span 1;
                    }
                }
            }
        }

        &.coll_2 {
            grid-template-columns: repeat(2, calc(50% - 5px));

            .coll-bottomMenu {
                grid-column-end: span 2;

                .menu-node > ul {
                    grid-template-columns: repeat(2, calc(50% - 5px));

                    > li:last-child {
                        grid-column-end: span 2;
                    }
                }
            }
        }

        &.coll_3 {
            grid-template-columns: repeat(3, calc((100% / 3) - (20px / 3)));

            .coll-bottomMenu {
                grid-column-end: span 3;

                .menu-node > ul {
                    grid-template-columns: repeat(3, calc((100% / 3) - (20px / 3)));

                    > li:last-child {
                        grid-column-end: span 3;
                    }
                }
            }
        }

        &.coll_4 {
            grid-template-columns: repeat(4, calc(25% - 7.5px));

            .coll-bottomMenu {
                grid-column-end: span 4;

                .menu-node > ul {
                    grid-template-columns: repeat(4, calc(25% - 7.5px));

                    > li:last-child {
                        grid-column-end: span 4;
                    }
                }
            }
        }

        .menuColl {
            @apply tw-content-start tw-grid tw-m-0;
            grid-row-gap: 20px;
            grid-template-columns: 100%;

            &.coll-bottomMenu .menu-node > ul {
                @apply tw-w-full tw-left-auto;
                display: grid;
                grid-column-gap: 10px;
                grid-row-gap: 10px;

                li {
                    @apply tw-m-0
                }
            }

            .menu-node {
                @apply tw-content-start tw-grid tw-m-0;
                grid-row-gap: 10px;
                grid-template-columns: 100%;

                h2 {
                    @apply tw-m-0;
                }

                ul {
                    display: grid;
                    grid-row-gap: 10px;

                    li {
                        @apply tw-m-0
                    }
                }
            }
        }
    }
}
