
.subMenuNavigationLinks {
	display: grid;
	gap: 26px;
	padding: 26px 0px 26px 0;
	flex: 3 1 auto;

	@screen smBsOnly {
		padding-right: 26px;
	}
}
.subMenu {
	@apply tw-hidden tw-absolute;
	z-index: 150;
	max-width: 100%;

	.subMenu-max {
		@apply tw-flex tw-justify-between tw-bg-white;
		width: 1600px;
		max-width: 100%;
		padding: 0;
		left: 0;
		right: 0;
		min-height: 480px;
		padding: 0 0 0 25px;
		top: 32px;
		z-index: 151;
		border-top: 3px solid theme("colors.gray.400");
		grid-template-columns: auto;
	}

	&.coll_1 {
		grid-template-columns: 2fr 10fr;

		.subMenuNavigationLinks {
			grid-template-columns: 1fr;
		}

		.menuColl {
			padding-right: 10px;
		}
	}

	&.coll_2 {
		grid-template-columns: 3.7fr 8.3fr;

		.subMenuNavigationLinks {
			grid-template-columns: repeat(2, 1fr);
		}

		.menuColl:nth-of-type(2) {
			padding-right: 10px;
		}
	}

	&.coll_3 {
		grid-template-columns: 562fr 614fr;

		.subMenuNavigationLinks {
			grid-template-columns: repeat(3, 4fr);
		}

		.menuColl:nth-of-type(3) {
			padding-right: 10px;
		}
	}

	&.coll_4 {
		grid-template-columns: 7.6fr 4.25fr;

		.subMenuNavigationLinks {
			grid-template-columns: repeat(4, 3fr);
		}

		.menuColl:nth-of-type(4) {
			padding-right: 10px;
		}
	}

	@screen smBsOnly {
		&.coll_1, &.coll_2, &.coll_3, &.coll_4 {
			grid-template-columns: 1fr;
		}
	}

	.menu-node {
		margin: 0 0 15px;

		h2 {
			@apply tw-font-bold tw-text-gray tw-text-base;
			letter-spacing: 1px;
			line-height: 18px;
			margin: 0 0 10px;
			width: 90%;
		}

		ul li {
			margin: 0 0 10px;
			a {
				@apply tw-font-normal tw-text-gray tw-text-sm tw-no-underline;
				line-height: 18px;

				&:hover,
				&:focus {
				@apply tw-underline tw-text-blue;
				}
			}
		}
	}

	.menu-node-heading {
		@apply tw-font-bold tw-leading-tight tw-text-base tw-text-gray tw-no-underline;
	}

	.menu-node-heading-link {
		@apply  tw-no-underline;

		&:hover,
		&:focus {
			@apply tw-underline tw-text-blue;
		}
	}

	.menuColl {
		margin: 0 5px;

		@apply tw-content-start tw-grid tw-m-0;
		grid-row-gap: 20px;
		grid-template-columns: 100%;

		&.coll-bottomMenu .menu-node > ul {
			@apply tw-w-full tw-left-auto;
			display: grid;
			grid-column-gap: 10px;
			grid-row-gap: 10px;

			li {
				@apply tw-m-0;
			}
		}

		.menu-node {
			@apply tw-content-start tw-grid tw-m-0;
			grid-row-gap: 10px;
			grid-template-columns: 100%;

			h2 {
				@apply tw-m-0;
			}

			ul {
				display: grid;
				grid-row-gap: 10px;

				li {
					@apply tw-m-0;
				}
			}
		}

		:global .clearance {
			h2,
			ul > li > a {
				@apply tw-text-red tw-underline tw-font-semibold;
			}
            ul > li > a:hover, ul > li > a:focus {
                @apply tw-text-blue;
				outline: 1px dotted theme('colors.gray.300');
		    	outline-offset: 2px;
		    	box-shadow: 0px 0px 1px theme('colors.gray.400');
            }
		}
	}
}
