.accountNudge {
	@apply tw-absolute tw-bg-gray-600 tw-border tw-border-gray-400 tw-leading-normal tw-mt-[10px] tw-p-4 tw-right-[5px] tw-shadow-[0_1px_2px_0_rgba(0,0,0,0.1)] tw-top-full tw-w-[320px];

	@screen md {
		@apply tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-t-gray-400 tw-border-t-[3px] tw-mt-[5px] -tw-right-[65px] tw-z-[160];
	}
}

.accountNudgeHeading {
	@apply tw-heading-5 tw-mb-3;
}

.accountNudgeClose {
	@apply tw-absolute tw-top-[14px] tw-right-[10px];
}

.accountNudgeList {
	@apply tw-leading-6 tw-ml-[30px];

	li {
		@apply tw-pl-2;
	}
}

.accountNudgeButtons {
	@apply tw-flex tw-flex-col;

	:global .Button {
		@apply tw-mb-3;
	}
}
