@media screen {
	.shopThisRoomLink {
		height: 34px;
		padding: 5px 0;
		width: 93px;

		&.abs-right {
			position: absolute;
			right: 10px;
			bottom: 10px;
		}
		&.abs-left {
			position: absolute;
			left: 10px;
			bottom: 10px;
		}
		.shopThisRoomLinkText {
			margin: 0 10px;
		}
		.shopIcon {
			width: 26px !important;
			margin-left: 10px;
		}
	}
}
