.hoverStyle {
	.hoverFill {
		fill: theme('colors.gray.DEFAULT');
	}
}

.cartSvg {
	&:focus, &.focus,
	&:hover, &.hover {
		@extend .hoverStyle;
	}
}

:hover > .cartSvg {
	@extend .hoverStyle;
}
