.root {
	display: flex;
	align-items: center;
	font-size: 15px;
	color: theme('colors.gray.100');
	text-decoration: none;
	cursor: pointer;
	background-color: hsla(0,0%,100%,.9);
	border: 1px solid theme('colors.gray.100');
	border-radius: 20px;

	svg {
		stroke: theme('colors.gray.100');
		fill: transparent;
	}

	&:focus, &:hover {
		background-color: theme('colors.gray.100');
		color: theme('colors.white.DEFAULT');

		svg, img {
			filter: invert(1);
		}
	}

	&:focus {
		border-color: theme('colors.white.DEFAULT');
		box-shadow: 0 0 4px theme('colors.gray.100') !important;
		outline: none !important;
	}
}
