.site-marketing-message {
	@apply tw-flex tw-h-full tw-items-center tw-justify-center tw-min-h-[40px] tw-p-2;

	a, p, span {
		@apply tw-m-0 tw-text-white tw-text-xs md:tw-text-sm;;
	}

	a:focus, a:hover {
		@apply tw-outline-1 tw-outline-dotted tw-outline-offset-2 tw-outline-white tw-shadow-none #{'!important'};
	}

	p {
		@apply tw-cursor-text tw-select-text tw-text-center;
	}
}
