@import 'css/lib/tailwind-custom/triangle';

.top-link {
	@apply tw-self-start tw-block tw-relative tw-text-center tw-cursor-pointer;
	margin: 0 0 0 20px;
	width: 34px;
	height: 27px;

	&:hover,
	&:focus {
		&::after {
			width: 100%;
		}
		&::before {
			opacity: 1;
		}
		.hover-fill {
			fill: theme('colors.gray.DEFAULT');
		}
	}

	&::after {
		@apply tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
		content: '';
		transition: width 0.2s ease-in-out;
		border-top: 3px solid theme('colors.gray.DEFAULT');
		bottom: -8px;
		z-index: 161;
	}

	svg {
		width: 100%;

		.hover-fill {
			fill: theme('colors.white.DEFAULT');
		}
	}

	&.has-menu {
		&::before {
			@apply tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
			content: '';
			@include triangle(8px, theme('colors.gray.DEFAULT'), down);
			transition: opacity 0.2s ease-in-out;
			bottom: -16px;
			z-index: 161;
		}
	}
}
